const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const categorySelectionPageUrl = '/category-list/';
const brandSelectionPageUrl = '/brands/';

globalThis.Convermax.handleProductCompareClick = ({ target }) => {
  target.closest('.custom-compare').classList.toggle('clicked');

  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );

  const compareBtn = window.document.body.querySelector('.navUser-item--compare');
  if (!compareBtn) {
    return;
  }

  compareBtn.classList.toggle('show', productsIdsToCompare.length > 1);
  compareBtn.querySelector('.navUser-action .countPill').textContent = productsIdsToCompare.length;
  compareBtn.href = `/compare/${productsIdsToCompare.join('/')}`;
};

globalThis.Convermax.onCardImageSliderClick = (e) => {
  if (e.currentTarget.querySelector('.card-img-container')) {
    const images = [...e.currentTarget.querySelector('.card-img-container').childNodes];
    const activeImage = e.currentTarget.querySelector('.is-active');
    const sliderButton = e.target.closest('.card-image-prev, .card-image-next');

    if (sliderButton) {
      e.preventDefault();
      e.stopPropagation();

      activeImage.classList.remove('is-active');

      if (sliderButton.classList.contains('card-image-prev')) {
        if (activeImage.classList.contains('first')) {
          images.find((img) => img.classList.contains('last'))?.classList?.add('is-active');
        } else {
          activeImage.previousElementSibling.classList.add('is-active');
        }
      } else {
        if (activeImage.classList.contains('last')) {
          images.find((img) => img.classList.contains('first'))?.classList?.add('is-active');
        } else {
          activeImage.nextElementSibling.classList.add('is-active');
        }
      }
    }
  }
};

export default {
  platform: 'bigcommerce',
  defaultView: 'grid',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  pagination: {
    centralRange: 1,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: ['brand', 'category'].map((className) => `body.${className} .page`).join(),
      template: 'SearchPage',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchBox',
      location: '#quickSearch',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        insertAfter: '.navUser .navUser-item--wishlist',
        class: 'cm_desktop-hide',
        wrapper: 'li',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm_ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 768,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.navUser .navUser-item--cart',
        wrapper: 'li',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        insertBefore: 'body.product section.productView-details .productView-info',
      },
      columnBreakpoint: 9999,
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: {
        insertAfter: '.productView-description .tabs .tab:first-child',
        wrapper: 'li',
        class: 'tab',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: '#cm_fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_collection-list',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: 'body.brands .brandGrid',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields: ['Universal'],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
