
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('a', {
        'className': 'tab-title',
        'href': '#tab-fitment',
        'key': '0'
    }, _createElement('span', {
        'className': 'theme-icon',
        'data-icon': '\uE90A'
    }), _createElement('span', { 'className': 'show fitment-table-title' }, 'Fitment')) : null;
}
        export const componentNames = []