export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary theme-btn-primary',
  BUTTON_SECONDARY_CLASS: 'button theme-btn-secondary',

  PRODUCT_REPEATER_CLASS: 'is-open',

  FACET_BUTTON_CLASS: '',
  FACET_TITLE_ICON: `
  <i rt-if="!this.isCollapsed" class="icon minus" aria-hidden="true"><svg><use xlinkHref="#icon-remove"></use></svg></i>
  <i rt-if="this.isCollapsed" class="icon plus" aria-hidden="true"><svg><use xlinkHref="#icon-add"></use></svg></i>
  `,

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  SEARCH_BOX_PLACEHOLDER: 'Search the store',
  SEARCH_BOX_FORM_CLASS: 'form-field',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'cm_icon-search-container',

  GARAGE_SIZE: '<span class="garage-size" key="garage-size">( {{size}} )</span>',

  CHANGE_VEHICLE: 'Change',
  DISCARD_VEHICLE: 'Clear',
};
